@import '~antd/dist/antd.less';

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@primary-color: #3ba560;@primary-color-hover: #3ba560;@primary-color-active: #3ba560;@primary-color-outline: #405AA3;@btn-danger-bg: #66182D;@success-color-deprecated-bg: #3ba560;@success-color-deprecated-border: #3ba560;@warning-color-deprecated-bg: #405AA3;@warning-color-deprecated-border: #405AA3;@info-color: #99AEE8;@layout-header-background: #6D7CA3;@shadow-color: #405AA3;